/* Import the Google Font */
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

* {
  font-family: 'Fredoka One', cursive;
}

body, html {
  background-image: url('../public/Pattern.png'); /* Adjust if the path to your image is different */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
  min-height: 100vh;
  width: 100vw;
  text-align: center;
}

.App {
  font-family: 'Fredoka One', cursive;
  padding: 40px; /* Increased padding for a larger container */
  text-align: center;
  max-width: 600px; /* Increase max-width for a larger container */
  margin: 40px auto;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  backdrop-filter: blur(10px); /* Blur effect for the background */
  border-radius: 16px; /* Larger border-radius for a softer look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adjusted shadow for depth */
  color: #fff; /* White text for content inside .App */
  overflow: auto; /* Allows scrolling within the .App container if content overflows */
  margin-bottom: 100px;
}

input {
  padding: 12px 20px; /* Increased padding for a better look */
  margin: 10px 0 20px; /* Increased margin-bottom for spacing */
  width: calc(100% - 40px); /* Width adjusted to fit within the container padding */
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white background */
  border: 1px solid rgba(255, 255, 255, 0.3); /* Light white border */
  border-radius: 8px; /* Softer border-radius */
  font-size: 18px; /* Larger font size for better readability */
  color: #fff; /* Black color for input text */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #fff; /* White font color for labels */
}

button {
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
}

button:hover {
  background-color: #0056b3;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: translateY(-150px);
}

.voucher-info {
  font-size: 1.5em;
  margin-top: 20px;
}

.voucher-info label {
  font-weight: bold;
}

.voucher-info span {
  font-weight: normal;
}
.logout-btn {
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
  background-color: #dc3545; /* Different color for logout */
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.2s ease-in-out;
  align-self: center;
}

.logout-btn:hover {
  background-color: #c82333;
}

.underline-text {
  text-decoration: underline;
}